import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "./Registration";
import merchantReducer from "./Merchant";
import areasReducer from "./Areas";
import categoriesReducer from "./Categories";
import warehouseReducer from "./Warehouse";
import itemsReducer from "./Items";
import ordersReducer from "./Orders";

const rootReducer = combineReducers({
  auth: authReducer,
  merchant: merchantReducer,
  areas: areasReducer,
  categories: categoriesReducer,
  warehouse: warehouseReducer,
  items: itemsReducer,
  orders: ordersReducer,
});

export default rootReducer;
