import { Suspense } from "react";
import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Layout,
  Panel,
  Storage,
  WarehouseLayout,
  Warehouse,
  Prices,
  ProductContent,
  Profile,
  CRM,
  Waiting,
  Registration,
  Verify,
  ForgotPasswort,
  AddCompany,
  AddCompanyFinish,
  AuthLayout,
  LoginLayout,
} from "../src/router/router";
import { getToken, destroyToken } from "./utils/Token";
import { PagesSpinner } from "./components/Loaders";
import "./App.css";
import "antd/dist/reset.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "./styles/styles.scss";

function App() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const token = getToken();

  useEffect(() => {
    if (!token && pathname.includes("layout")) {
      navigate("/");
    }
    if (
      pathname === "/" &&
      token &&
      token?.is_filled_profile &&
      token?.merchant_activated
    ) {
      navigate("/layout");
    }
    if (token && !token?.is_filled_profile) {
      navigate("/layout/addCompany");
    }
    if (token && token?.is_filled_profile && !token?.merchant_activated) {
      navigate("/layout/waiting");
    }
    if (
      pathname === "/layout/addCompany" &&
      token?.is_filled_profile &&
      token?.merchant_activated
    ) {
      navigate("/layout");
    }

    if (
      pathname === "/layout/waiting" &&
      token?.is_filled_profile &&
      token?.merchant_activated
    ) {
      navigate("/layout");
    }

    if (token && token?.role_id !== 3) {
      destroyToken();
    }
  }, []);
  return (
    <div>
      <Routes>
        <Route
          path="/"
          element={
            <Suspense
              fallback={
                <div className="flex justify-center">
                  <PagesSpinner />
                </div>
              }
            >
              <AuthLayout />
            </Suspense>
          }
        >
          <Route
            index
            element={
              <Suspense
                fallback={
                  <div className="flex justify-center">
                    <PagesSpinner />
                  </div>
                }
              >
                <LoginLayout />
              </Suspense>
            }
          />
          <Route
            path="registration"
            element={
              <Suspense
                fallback={
                  <div className="flex justify-center">
                    <PagesSpinner />
                  </div>
                }
              >
                <Registration />
              </Suspense>
            }
          />
          <Route
            path="verify"
            element={
              <Suspense
                fallback={
                  <div className="flex justify-center">
                    <PagesSpinner />
                  </div>
                }
              >
                <Verify />
              </Suspense>
            }
          />

          <Route
            path="forgotPassword"
            element={
              <Suspense
                fallback={
                  <div className="flex justify-center">
                    <PagesSpinner />
                  </div>
                }
              >
                <ForgotPasswort />
              </Suspense>
            }
          />
        </Route>
        <Route
          path="layout"
          element={
            <Suspense
              fallback={
                <div className="flex justify-center">
                  <PagesSpinner />
                </div>
              }
            >
              <Layout />
            </Suspense>
          }
        >
          <Route
            index
            element={
              <Suspense
                fallback={
                  <div className="flex justify-center">
                    <PagesSpinner />
                  </div>
                }
              >
                <CRM />
              </Suspense>
            }
          />
          <Route
            path="panel"
            element={
              <Suspense
                fallback={
                  <div className="flex justify-center">
                    <PagesSpinner />
                  </div>
                }
              >
                <Panel />
              </Suspense>
            }
          />
          <Route
            path="storage"
            element={
              <Suspense
                fallback={
                  <div className="flex justify-center">
                    <PagesSpinner />
                  </div>
                }
              >
                <WarehouseLayout />
              </Suspense>
            }
          >
            <Route
              index
              element={
                <Suspense
                  fallback={
                    <div className="flex justify-center">
                      <PagesSpinner />
                    </div>
                  }
                >
                  <Storage />
                </Suspense>
              }
            />

            <Route
              path="warehouse"
              element={
                <Suspense
                  fallback={
                    <div className="flex justify-center">
                      <PagesSpinner />
                    </div>
                  }
                >
                  <Warehouse />
                </Suspense>
              }
            />
          </Route>

          <Route
            path="price"
            element={
              <Suspense
                fallback={
                  <div className="flex justify-center">
                    <PagesSpinner />
                  </div>
                }
              >
                <Prices />
              </Suspense>
            }
          />

          <Route
            path="productContent"
            element={
              <Suspense
                fallback={
                  <div className="flex justify-center">
                    <PagesSpinner />
                  </div>
                }
              >
                <ProductContent />
              </Suspense>
            }
          />

          <Route
            path="profile"
            element={
              <Suspense
                fallback={
                  <div className="flex justify-center">
                    <PagesSpinner />
                  </div>
                }
              >
                <Profile />
              </Suspense>
            }
          />

          <Route
            path="addCompany"
            element={
              <Suspense
                fallback={
                  <div className="flex justify-center">
                    <PagesSpinner />
                  </div>
                }
              >
                <AddCompany />
              </Suspense>
            }
          />

          <Route
            path="addCompanyFinish"
            element={
              <Suspense
                fallback={
                  <div className="flex justify-center">
                    <PagesSpinner />
                  </div>
                }
              >
                <AddCompanyFinish />
              </Suspense>
            }
          />

          <Route
            path="waiting"
            element={
              <Suspense
                fallback={
                  <div>
                    <PagesSpinner />
                  </div>
                }
              >
                <Waiting />
              </Suspense>
            }
          />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
