import { lazy } from "react";

export const AuthLayout = lazy(() => import("../container/auth/AuthLayout"));
export const LoginLayout = lazy(() => import("../container/auth/LoginLayout"));

export const Registration = lazy(() =>
  import("../container/auth/Registration")
);
export const Verify = lazy(() => import("../container/auth/Verify"));
export const ForgotPasswort = lazy(() =>
  import("../container/auth/ForgotPassword")
);
export const AddCompany = lazy(() =>
  import("../container/fillRegistration/MerchantRegistry")
);
export const AddCompanyFinish = lazy(() =>
  import("../container/fillRegistration/MerchantRegistryFinish")
);
export const Waiting = lazy(() =>
  import("../container/fillRegistration/Waiting")
);
export const Layout = lazy(() => import("../container/layout/Layout"));
export const Panel = lazy(() => import("../container/layoutOutlets/Panel"));
export const CRM = lazy(() => import("../container/layoutOutlets/CRM"));
export const WarehouseLayout = lazy(() =>
  import("../container/layoutOutlets/warehouse/WarehouseLayout")
);
export const Storage = lazy(() =>
  import("../container/layoutOutlets/warehouse/warehouseOutlets/Storage")
);
export const Warehouse = lazy(() =>
  import("../container/layoutOutlets/warehouse/warehouseOutlets/Warehouse")
);
export const Prices = lazy(() => import("../container/layoutOutlets/Prices"));
export const ProductContent = lazy(() =>
  import("../container/layoutOutlets/ProductsContent")
);
export const Profile = lazy(() => import("../container/layoutOutlets/Profile"));
