import React from "react";

function PagesSpinner() {
  return (
    <div className="spinner">
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
}

function TableSpinner() {
  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center bg-[#F4F7F9] bg-opacity-70 z-50 w-full h-screen">
      <div className="dot-spinner m-auto">
        <div className="dot-spinner__dot"></div>
        <div className="dot-spinner__dot"></div>
        <div className="dot-spinner__dot"></div>
        <div className="dot-spinner__dot"></div>
        <div className="dot-spinner__dot"></div>
        <div className="dot-spinner__dot"></div>
        <div className="dot-spinner__dot"></div>
        <div className="dot-spinner__dot"></div>
      </div>
    </div>
  );
}

export { PagesSpinner, TableSpinner };
