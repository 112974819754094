import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message, notification } from "antd";
import { axiosBaseRequest } from "../utils/axiosBaseUrl";

const initialState = {
  logo: null,
  merchantInfo: {},
  loading: false,
  status: "",
  error: "",
};
export const addMerchant = createAsyncThunk(
  "users/addMerchant",
  async ({ body, file, navigate }, { rejectWithValue, dispatch }) => {
    try {
      let uri = `users/merchant`;

      const { data } = await axiosBaseRequest.post(uri, body);

      dispatch(addMerchantsLogo({ body: file, id: data.id }));
      navigate("/layout/addCompanyFinish");
      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);
export const addMerchantsLogo = createAsyncThunk(
  "users/addMerchantLogo",
  async ({ body, id }, { rejectWithValue }) => {
    try {
      let uri = `users/merchant/logo/?merchant_id=${id}`;
      const { data } = await axiosBaseRequest.post(uri, body, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const getMerchant = createAsyncThunk(
  "users/getMerchantInfo",
  async (_, { rejectWithValue }) => {
    let uri = `users/merchant`;
    try {
      const { data } = await axiosBaseRequest.get(uri);
      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const merchantUpdate = createAsyncThunk(
  "merchant/merchantUpdate",
  async ({ body, id }, { rejectWithValue, dispatch }) => {
    let uri = `users/merchant`;

    try {
      const { data } = await axiosBaseRequest.patch(`${uri}/${id}`, body);

      dispatch(getMerchant());
      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

const merchantSlice = createSlice({
  name: "merchant",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addMerchantsLogo.pending, (state, action) => {
      return { ...state, status: "pending" };
    });
    builder.addCase(addMerchantsLogo.fulfilled, (state, action) => {
      state.logo = action.payload;
    });
    builder.addCase(addMerchantsLogo.rejected, (state, action) => {
      state.status = "rejected";
      state.error = action.payload;
    });

    builder.addCase(getMerchant.pending, (state, action) => {
      return { ...state, status: "pending" };
    });
    builder.addCase(getMerchant.fulfilled, (state, action) => {
      state.merchantInfo = action.payload;
    });
    builder.addCase(getMerchant.rejected, (state, action) => {
      state.status = "rejected";
      state.error = action.payload;
    });

    builder.addCase(merchantUpdate.pending, (state, action) => {
      return { ...state, status: "pending" };
    });
    builder.addCase(merchantUpdate.fulfilled, (state, action) => {
      notification["success"]({
        description: "Профиль успешно изменен!",
      });
    });
    builder.addCase(merchantUpdate.rejected, (state, action) => {
      state.status = "rejected";
      state.error = action.payload;
    });

    builder.addCase(addMerchant.pending, (state, action) => {
      state.status = "pending";
      state.loading = true;
    });
    builder.addCase(addMerchant.fulfilled, (state, action) => {
      state.loading = false;
      notification["success"]({
        description: "Заявка принята!",
      });
    });
    builder.addCase(addMerchant.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.payload;
    });
  },
});

export default merchantSlice.reducer;
