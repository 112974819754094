import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosOrdersRequest } from "../utils/axiosOrdersURL";
import { message } from "antd";

export const getAllOrders = createAsyncThunk(
  "orders/getAllOrders",
  async ({ offset, query }, { rejectWithValue }) => {
    try {
      let uri = `order/merchant`;
      if (offset !== undefined) {
        uri += `?offset=${offset}&limit=6`;
      }
      if (query !== undefined) {
        uri += `?q=${query}`;
      }

      const { data } = await axiosOrdersRequest.get(uri);

      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const itemForCourier = createAsyncThunk(
  "orders/forcourier",
  async (body, { rejectWithValue, dispatch }) => {
    try {
      let uri = `order/merchant/item/status?item_id=${body.item_id}&order_id=${body.order_id}&storage_id=${body.storage_id}`;

      const { data } = await axiosOrdersRequest.post(uri);
      dispatch(getAllOrders({ offset: 0, query: undefined }));
      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  ordersData: { orders: [] },
  loading: false,
  error: "",
  courierModal: false,
};

const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    setCourierModalAC(state, action) {
      state.courierModal = action.payload;
    },
    addRealObj: (state, action) => {
      let loopState = false;
      if (!state.ordersData.orders) {
        state.ordersData.orders = [action.payload];
      } else {
        for (let i = 0; i <= state.ordersData.orders?.length; i++) {
          if (
            state.ordersData.orders[i]?.order_uuid === action.payload.order_uuid
          ) {
            state.ordersData.orders?.splice(i, 1, action.payload);
            loopState = true;
            break;
          }
        }
        !loopState && state.ordersData.orders?.unshift(action.payload);
      }
    },
  },
  extraReducers: {
    [getAllOrders.pending]: (state) => {
      state.loading = true;
    },
    [getAllOrders.fulfilled]: (state, action) => {
      state.loading = false;
      state.ordersData = action.payload;
    },
    [getAllOrders.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [itemForCourier.pending]: (state) => {
      state.loading = true;
    },
    [itemForCourier.fulfilled]: (state, action) => {
      state.loading = false;
      state.ordersData = action.payload;
      state.courierModal = false;
    },
    [itemForCourier.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.courierModal = false;
    },
  },
});

export const { setCourierModalAC, addRealObj } = ordersSlice.actions;
export default ordersSlice.reducer;
