import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { axiosBaseRequest } from "../utils/axiosBaseUrl";

const initialState = {
  warehouse: {},
  warehouses: [],
  status: "",
  error: "",
  loading: false,
};

export const addWarehouse = createAsyncThunk(
  "warehouse/addWarehouse",
  async ({ body, id }, { rejectWithValue, dispatch }) => {
    try {
      let uri = `warehouse`;
      const { data } = await axiosBaseRequest.post(uri, body);
      dispatch(getWarehouses({ id }));

      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const getWarehouses = createAsyncThunk(
  "warehouse/getWarehouses",
  async ({ id, q }, { rejectWithValue }) => {
    try {
      let uri = `warehouse/?merchant_id=${id}`;
      if (q !== undefined) {
        uri += `&q=${q}`;
      }

      const { data } = await axiosBaseRequest.get(uri);
      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

const warehouseSlice = createSlice({
  name: "warehouse",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addWarehouse.pending, (state, action) => {
      return { ...state, status: "pending" };
    });
    builder.addCase(addWarehouse.fulfilled, (state, action) => {
      state.status = "Success";
    });
    builder.addCase(addWarehouse.rejected, (state, action) => {
      state.status = "rejected";
      state.error = action.payload;
    });

    builder.addCase(getWarehouses.pending, (state, action) => {
      state.status = "pending";
      state.loading = true;
    });
    builder.addCase(getWarehouses.fulfilled, (state, action) => {
      state.status = "Success";
      state.loading = false;
      state.warehouses = action.payload;
    });
    builder.addCase(getWarehouses.rejected, (state, action) => {
      state.status = "rejected";
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default warehouseSlice.reducer;
