import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message, notification } from "antd";
import { axiosBaseRequest } from "../utils/axiosBaseUrl";

const initialState = {
  item: null,
  items: [],
  units: [],
  backorderTypes: [],
  loading: false,
  loading2: false,
  priceOffcet: 1,
  productOffcet: 1,
  storageOffcet: 1,
  status: "",
  error: "",
  deleteModalVisible: false,
  discountModalVisible: false,
};

export const addItem = createAsyncThunk(
  "items/addItem",
  async ({ body, file }, { rejectWithValue, dispatch, getState }) => {
    try {
      let uri = `items`;
      const { data } = await axiosBaseRequest.post(uri, body);
      if (file !== null) {
        dispatch(addItemImage({ body: file, id: data.item_id }));
      }
      dispatch(
        getItems({
          offset: (getState().items.productOffcet - 1) * 7,
          query: undefined,
        })
      );
      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);
export const deleteItem = createAsyncThunk(
  "items/deleteItem",
  async (itemId, { rejectWithValue, dispatch, getState }) => {
    try {
      let uri = `items/${itemId}`;
      const { data } = await axiosBaseRequest.delete(uri);
      dispatch(
        getItems({
          offset: (getState().items.productOffcet - 1) * 7,
          query: undefined,
        })
      );
      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const addItemImage = createAsyncThunk(
  "items/addItemImage",
  async ({ body, id }, { rejectWithValue, dispatch, getState }) => {
    try {
      let uri = `items/images/?item_id=${id}`;
      const { data } = await axiosBaseRequest.post(uri, body, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      dispatch(
        getItems({
          offset: (getState().items.productOffcet - 1) * 7,
          query: undefined,
        })
      );
      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const getItems = createAsyncThunk(
  "items/getItems",
  async ({ query, offset }, { rejectWithValue }) => {
    try {
      let uri = `items/all`;
      if (offset !== undefined) {
        uri += `?offset=${offset}&limit=7`;
      }
      if (query !== undefined) {
        uri += `?q=${query}`;
      }

      const { data } = await axiosBaseRequest.get(uri);
      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const itemUpdate = createAsyncThunk(
  "items/itemUpdate",
  async ({ body, id }, { rejectWithValue, dispatch, getState }) => {
    let uri = `items`;

    try {
      const { data } = await axiosBaseRequest.patch(`${uri}/${id}`, body);

      dispatch(
        getItems({
          offset: (getState().items.productOffcet - 1) * 7,
          query: undefined,
        })
      );
      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const deleteItemImage = createAsyncThunk(
  "items/deleteItemImage",
  async (id, { rejectWithValue }) => {
    let uri = `items/images`;

    try {
      const { data } = await axiosBaseRequest.delete(`${uri}/${id}`);
      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const getOneItem = createAsyncThunk(
  "items/getOneItem",
  async (id, { rejectWithValue }) => {
    try {
      let uri = `items/${id}`;
      const { data } = await axiosBaseRequest.get(uri);
      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const archiveItem = createAsyncThunk(
  "items/archiveItem",
  async (itemId, { rejectWithValue, dispatch, getState }) => {
    try {
      let uri = `items/archive?item_id=${itemId}`;
      const { data } = await axiosBaseRequest.post(uri);

      dispatch(
        getItems({
          offset: (getState().items.productOffcet - 1) * 7,
          query: undefined,
        })
      );
      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const getUnits = createAsyncThunk(
  "items/getUnits",
  async (_, { rejectWithValue }) => {
    try {
      let uri = `commons/units`;
      const { data } = await axiosBaseRequest.get(uri);
      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const getBackorderTypes = createAsyncThunk(
  "items/getBackorderTypes",
  async (_, { rejectWithValue }) => {
    try {
      let uri = `commons/backorder_types`;
      const { data } = await axiosBaseRequest.get(uri);
      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const addItemToStorage = createAsyncThunk(
  "warehouse/addItemToStorage",
  async (body, { rejectWithValue, dispatch, getState }) => {
    try {
      let uri = `items/warehouse/`;

      const { data } = await axiosBaseRequest.post(uri, body);
      dispatch(
        getItems({
          offset: (getState().items.storageOffcet - 1) * 7,
          query: undefined,
        })
      );
      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const addItemDoc = createAsyncThunk(
  "warehouse/addItemDoc",
  async ({ body, id }, { rejectWithValue, dispatch }) => {
    try {
      let uri = `warehouse/doc/?storage_id=${id}`;
      const { data } = await axiosBaseRequest.post(uri, body, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      dispatch(getItems({}));
      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const itemInStorageUpdate = createAsyncThunk(
  "items/itemInStorageUpdate",
  async ({ body, id }, { rejectWithValue, dispatch, getState }) => {
    let uri = `items/warehouse`;

    try {
      const { data } = await axiosBaseRequest.patch(`${uri}/${id}`, body);

      dispatch(
        getItems({
          offset: (getState().items.storageOffcet - 1) * 7,
          query: undefined,
        })
      );
      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const storageTransfer = createAsyncThunk(
  "items/storageTransfer",
  async ({ body, id }, { rejectWithValue, dispatch, getState }) => {
    let uri = `items/warehouse/move`;

    try {
      const { data } = await axiosBaseRequest.patch(`${uri}/${id}`, body);

      dispatch(
        getItems({
          offset: (getState().items.storageOffcet - 1) * 7,
          query: undefined,
        })
      );
      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const itemPrice = createAsyncThunk(
  "items/itemPrice",
  async (body, { rejectWithValue, dispatch, getState }) => {
    try {
      let uri = `prices`;

      const { data } = await axiosBaseRequest.post(uri, body);
      dispatch(
        getItems({
          offset: (getState().items.priceOffcet - 1) * 7,
          query: undefined,
        })
      );
      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const itemPriceDiscount = createAsyncThunk(
  "items/itemPriceDicscount",
  async (body, { rejectWithValue, dispatch, getState }) => {
    try {
      let uri = `prices/discount`;

      const { data } = await axiosBaseRequest.post(uri, body);
      dispatch(
        getItems({
          offset: (getState().items.priceOffcet - 1) * 7,
          query: undefined,
        })
      );
      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

const itemSlice = createSlice({
  name: "items",
  initialState,
  reducers: {
    setDeleteModalVisibleAC(state, action) {
      state.deleteModalVisible = action.payload;
    },
    setDiscountModalVisibleAC(state, action) {
      state.discountModalVisible = action.payload;
    },
    setPriceOffcet(state, action) {
      state.priceOffcet = action.payload;
    },
    setProductOffcet(state, action) {
      state.productOffcet = action.payload;
    },
    setStorageOffcet(state, action) {
      state.storageOffcet = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addItemImage.pending, (state, action) => {
      state.status = "pending";
      state.loading2 = true;
    });
    builder.addCase(addItemImage.fulfilled, (state, action) => {
      state.status = "Success";
      state.loading2 = false;
    });
    builder.addCase(addItemImage.rejected, (state, action) => {
      state.status = "rejected";
      state.error = action.payload;
      state.loading2 = false;
    });

    builder.addCase(addItem.pending, (state, action) => {
      state.status = "pending";
      state.loading2 = true;
    });
    builder.addCase(addItem.fulfilled, (state, action) => {
      state.status = "Success";
      state.loading2 = false;
    });
    builder.addCase(addItem.rejected, (state, action) => {
      state.status = "rejected";
      state.error = action.payload;
      state.loading2 = false;
    });
    builder.addCase(deleteItem.pending, (state, action) => {
      state.status = "pending";
      state.loading2 = true;
    });
    builder.addCase(deleteItem.fulfilled, (state, action) => {
      state.status = "Success";
      state.loading2 = false;
      state.deleteModalVisible = false;
      notification["success"]({
        description: "Товар успешно удалён!",
      });
    });
    builder.addCase(deleteItem.rejected, (state, action) => {
      state.status = "rejected";
      state.error = action.payload;
      state.loading2 = false;
      state.deleteModalVisible = false;
      notification["error"]({
        description: "Произошла ошибка при удалении товара!",
      });
    });

    builder.addCase(getItems.pending, (state, action) => {
      state.status = "pending";
      state.loading = true;
    });
    builder.addCase(getItems.fulfilled, (state, action) => {
      state.status = "Success";
      state.items = action.payload;
      state.loading = false;
    });
    builder.addCase(getItems.rejected, (state, action) => {
      state.status = "rejected";
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(getOneItem.pending, (state, action) => {
      return { ...state, status: "pending" };
    });
    builder.addCase(getOneItem.fulfilled, (state, action) => {
      state.status = "Success";
      state.item = action.payload;
    });
    builder.addCase(getOneItem.rejected, (state, action) => {
      state.status = "rejected";
      state.error = action.payload;
    });

    builder.addCase(getUnits.pending, (state, action) => {
      return { ...state, status: "pending" };
    });
    builder.addCase(getUnits.fulfilled, (state, action) => {
      state.status = "Success";
      state.units = action.payload;
    });
    builder.addCase(getUnits.rejected, (state, action) => {
      state.status = "rejected";
      state.error = action.payload;
    });

    builder.addCase(getBackorderTypes.pending, (state, action) => {
      state.status = "pending";
    });
    builder.addCase(getBackorderTypes.fulfilled, (state, action) => {
      state.status = "Success";
      state.backorderTypes = action.payload;
    });
    builder.addCase(getBackorderTypes.rejected, (state, action) => {
      state.status = "rejected";
      state.error = action.payload;
    });

    builder.addCase(addItemDoc.pending, (state, action) => {
      return { ...state, status: "pending" };
    });
    builder.addCase(addItemDoc.fulfilled, (state, action) => {
      state.status = "Success";
    });
    builder.addCase(addItemDoc.rejected, (state, action) => {
      state.status = "rejected";
      state.error = action.payload;
    });

    builder.addCase(addItemToStorage.pending, (state, action) => {
      return { ...state, status: "pending" };
    });
    builder.addCase(addItemToStorage.fulfilled, (state, action) => {
      state.status = "Success";
    });
    builder.addCase(addItemToStorage.rejected, (state, action) => {
      state.status = "rejected";
      state.error = action.payload;
    });

    builder.addCase(storageTransfer.pending, (state, action) => {
      state.status = "pending";
    });
    builder.addCase(storageTransfer.fulfilled, (state, action) => {
      state.status = "Success";
    });
    builder.addCase(storageTransfer.rejected, (state, action) => {
      state.status = "rejected";
      state.error = action.payload;
    });

    builder.addCase(itemPrice.pending, (state, action) => {
      return { ...state, status: "pending" };
    });
    builder.addCase(itemPrice.fulfilled, (state, action) => {
      state.status = "Success";
    });
    builder.addCase(itemPrice.rejected, (state, action) => {
      state.status = "rejected";
      state.error = action.payload;
    });
    builder.addCase(itemPriceDiscount.pending, (state, action) => {
      return { ...state, status: "pending" };
    });
    builder.addCase(itemPriceDiscount.fulfilled, (state, action) => {
      state.status = "Success";
      state.discountModalVisible = false;
    });
    builder.addCase(itemPriceDiscount.rejected, (state, action) => {
      state.status = "rejected";
      state.error = action.payload;
      state.discountModalVisible = false;
    });

    builder.addCase(itemUpdate.pending, (state, action) => {
      state.status = "pending";
      state.loading2 = true;
    });
    builder.addCase(itemUpdate.fulfilled, (state, action) => {
      state.loading2 = false;
      !state.loading2 &&
        notification["success"]({
          description: "Товар успешно изменен!",
        });
    });
    builder.addCase(itemUpdate.rejected, (state, action) => {
      state.loading2 = false;
      state.status = "rejected";
      state.error = action.payload;
    });

    builder.addCase(deleteItemImage.pending, (state, action) => {
      return { ...state, status: "pending" };
    });
    builder.addCase(deleteItemImage.fulfilled, (state, action) => {
      state.status = "Success";
    });
    builder.addCase(deleteItemImage.rejected, (state, action) => {
      state.status = "rejected";
      state.error = action.payload;
    });

    builder.addCase(itemInStorageUpdate.pending, (state, action) => {
      return { ...state, status: "pending" };
    });
    builder.addCase(itemInStorageUpdate.fulfilled, (state, action) => {
      state.status = "Success";
    });
    builder.addCase(itemInStorageUpdate.rejected, (state, action) => {
      state.status = "rejected";
      state.error = action.payload;
    });

    builder.addCase(archiveItem.pending, (state, action) => {
      return { ...state, status: "pending" };
    });
    builder.addCase(archiveItem.fulfilled, (state, action) => {
      state.status = "Success";
    });
    builder.addCase(archiveItem.rejected, (state, action) => {
      state.status = "rejected";
      state.error = action.payload;
    });
  },
});

export const {
  setDeleteModalVisibleAC,
  setDiscountModalVisibleAC,
  setPriceOffcet,
  setProductOffcet,
  setStorageOffcet,
} = itemSlice.actions;
export default itemSlice.reducer;
